
<div class="container">
    <div class="iq-get-in iq-pall-40 white-bg">
    <h4 class="text-center iq-tw-6 iq-pb-20 iq-mb-40 heading">{{dataTitle}}</h4>
    <div class="wrap">
       
        <div class="form_wrap shadow">
            <form [formGroup]="myReactiveForm" (ngSubmit)="onSubmit()">
                <div class="row"  *ngIf="showMsg">
                    <div class="col-xs-12">
                        <p class="alert alert-success" *ngIf="resData!=''">{{resData}}</p>
                    </div>
                  </div>
            <div class="mb-5 section-field">
                <!-- <label for="username" class="form-label">Name</label> -->
                <input type="text" class="form-control" id="username" placeholder="name" formControlName="name">
                <small class="text-danger" *ngIf = "!myReactiveForm.get('name').valid && myReactiveForm.get('name').touched">Please enter Name</small>
                
            </div>
            <div class="mb-5 section-field">
                <!-- <label for="email" class="form-label">Email address</label> -->
                <input type="email" class="form-control" id="email" placeholder="name@example.com" formControlName="email">
                <small class="text-danger" *ngIf = "!myReactiveForm.get('email').valid && myReactiveForm.get('email').touched">Please enter valid email</small>
            </div>
            <div class="mb-5 section-field">
                <!-- <label for="username" class="form-label">Subject</label> -->
                <input type="text" class="form-control" id="subject" placeholder="subject" formControlName="subject">
                <small class="text-danger" *ngIf = "!myReactiveForm.get('subject').valid && myReactiveForm.get('subject').touched">Please enter subject</small>

            </div>
            
            <div class="mb-5 section-field">
                <!-- <label for="textarea" class="form-label">Massage</label> -->
                <textarea class="form-control" id="textarea" rows="3" formControlName="msg" placeholder="message"></textarea>
                <small class="text-danger" *ngIf = "!myReactiveForm.get('msg').valid && myReactiveForm.get('msg').touched">Please enter msg</small>

            </div>
            <!-- <div class="form-control">
                <ngx-recaptcha2
                #captchaElem
                siteKey="6LdYbF0lAAAAACUMZrZm1deTW_uPEMNck078AmI9"
                (success)="handleSuccess($event)"
                [size]="size"
                [hl]="lang"
                [theme]="theme"
                [type]="type"
                formControlName="recaptcha"
              >
              </ngx-recaptcha2>
                </div> -->
            <button type="submit"  class="button iq-mt-30 btn_submit pull-right">Submit</button>
            
            </form>
        </div>
    </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
        
        </div>
    </div>
</div>
