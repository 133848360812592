import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FaqService } from 'src/app/services/faq.service';
import { ToastrService } from 'ngx-toastr';
import { ReCaptcha2Component } from 'ngx-captcha';

@Component({
  selector: 'app-contactushome',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactUsComponent implements OnInit {

  myReactiveForm: FormGroup;
  userData: any = {};
  dataTitle: string = "Get in Touch";
  resData: any = "";
  showMsg: boolean = false;
  siteKey: string;
  capcharesponse:boolean=false;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  @ViewChild('langInput') langInput: ElementRef;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';



  constructor(private _myUser: FaqService, private toastr: ToastrService,) {       
    this.siteKey = '6Le2X1oaAAAAAB737EF3-cFd21ZV2g59AqJct9If'
   // this.siteKey = '6LdYbF0lAAAAACUMZrZm1deTW_uPEMNck078AmI9'
  }
 
  ngOnInit(): void {
    this.myReactiveForm = new FormGroup({
      'name': new FormControl(null, [Validators.required]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'subject': new FormControl(null, [Validators.required]),
      'msg': new FormControl(null, [Validators.required]),
     // 'recaptcha':new FormControl,
    })
  }

  handleSuccess(value)
  {
    this.capcharesponse=true
  }


  

  onSubmit() {
  if (this.myReactiveForm.valid) {
    this.userData = this.myReactiveForm.value;
    this._myUser.saveData(this.userData).subscribe((response_data) => {
      this.resData = response_data['response_message'];
    })
    this.myReactiveForm.reset();
    this.showMsg = true
  } else {
    let key = Object.keys(this.myReactiveForm.controls)
    key.filter(data => {
      let control = this.myReactiveForm.controls[data];
      if (control.errors != null) {
        control.markAsTouched();
      }
    })
 
  }
   

  }

}
