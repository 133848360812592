import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CredentialService } from 'src/app/services/credential.service';
import { WebSocketService } from '../../services/web-socket.service';
import { ReCaptcha2Component } from 'ngx-captcha';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  siteKey: string;
  username:string;
  capcharesponse:boolean=false;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  @ViewChild('langInput') langInput: ElementRef;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  constructor(
      private _auth: AuthService,
      private router : Router,
      private toastr: ToastrService,
      public fb : FormBuilder,
      private _credentials: CredentialService,
      public dialog: MatDialog,
      private formBuilder: FormBuilder,
      private WebSocketService: WebSocketService,

      
    ) { 
     this.siteKey = '6Le2X1oaAAAAAB737EF3-cFd21ZV2g59AqJct9If'
    // this.siteKey = '6LdYbF0lAAAAACUMZrZm1deTW_uPEMNck078AmI9';
     }

  ngOnInit(): void {
    const rememberMeValue = ('; '+document.cookie).split(`; rememberMe=`).pop().split(';')[0];
    const userEmailOrName = ('; '+document.cookie).split(`; userName=`).pop().split(';')[0];
    this.username = rememberMeValue ? userEmailOrName : ""


    this.loginForm = this.fb.group({
      userName: [this.username, [Validators.required]],
      password: ['', [Validators.required, Validators.maxLength(6)]],
    //  recaptcha: ['', Validators.required],
      rememberMe: false
    })
    
  }
  handleSuccess(value)
  {
    this.capcharesponse=true
  }

  userLogin() {
    
    localStorage.clear();
    
  if (this.capcharesponse===false) 
  {
    this.toastr.error('Please check Capcha before Login.');
  }
  else
  {

    const loginFormValues = this.loginForm.getRawValue();
    document.cookie = 'rememberMe' + "=" + loginFormValues.rememberMe;
    this._auth.userLogin(this.loginForm.getRawValue())
    .subscribe((response : HttpResponse<any>)=>{
      if(response.status == 200||
        response.status == 201||
        response.status == 202||
        response.status == 204) {
          ;
        if (response.body.response_data.chatToken===undefined)
        {
          this.toastr.error(response.body.response_message);
        }
        else
        {
          
          
          localStorage.setItem("auth_data", JSON.stringify(response.body.response_data));
          const usersInfo = JSON.parse(localStorage.getItem('auth_data'))
          if(loginFormValues.rememberMe != false){
            document.cookie = 'userName' + "=" + loginFormValues.userName;
          } else {
            document.cookie = 'userName' + "=" + "";
          }
          
         
          this.WebSocketService.connect();
          var data={
            userId:response.body.response_data._id
          }
          this.WebSocketService.emit("userConneted",data);
          
          var data=
          {
            "userId":response.body.response_data._id
          }
          this.WebSocketService.emit("logoutEvent", data); 
          this._credentials.setCredentials(response.body.data);
          this.router.navigate(['/dashboard/contact']);
          this.toastr.success(response.body.response_message);
        
      
        }    
      } else {
        this.toastr.error(response.body.response_message);
      }
    }, error => {
      this.toastr.error(error.error.message);
    });
  }
  }

  forgotPassword() {
    this.dialog.open(ForgotPassword);
  }

}
@Component({
  selector: 'forgot-password',
  templateUrl: 'forgot-password.html',
  styleUrls: ['./login.component.scss'],
})
export class ForgotPassword {
  @ViewChild('forgotPassword') forgotPassword : ElementRef;

  constructor(
    private _auth: AuthService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ForgotPassword>) {}

  submitForgotPassword() {
    this._auth.forgotPassword(this.forgotPassword.nativeElement.value)
    .subscribe((response : HttpResponse<any>)=>{
      if(response.status == 200||
        response.status == 201||
        response.status == 202||
        response.status == 204) {
          this.toastr.success(response.body.response_message);
          this.dialogRef.close();
          // this.dialog.open(ChangePassword, {
          //   data: response.body.response_data._id
          // });
      } else {
        this.toastr.error(response.body.response_message);
      }
    }, error => {
      this.toastr.error(error.error.message);
    });
  }

  submitForgotUsername() {
    this._auth.forgotUsername(this.forgotPassword.nativeElement.value)
    .subscribe((response : HttpResponse<any>)=>{
      if(response.status == 200||
        response.status == 201||
        response.status == 202||
        response.status == 204) {
          this.toastr.success(response.body.response_message);
          this.dialogRef.close();
          // this.dialog.open(ChangePassword, {
          //   data: response.body.response_data._id
          // });
      } else {
        this.toastr.error(response.body.response_message);
      }
    }, error => {
      this.toastr.error(error.error.message);
    });
  }




  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'change-password',
  templateUrl: 'change-password.html',
})
export class ChangePassword {
  @ViewChild('currentPassword') currentPassword : ElementRef;
  @ViewChild('newPassword') newPassword : ElementRef;
  id: string;

  constructor(
    private _credentials: CredentialService,
    private _auth: AuthService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ChangePassword>,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.id = data;
    }

  submitChangePassword() {
    this._auth.changePassword(
      this.id,
      this.currentPassword.nativeElement.value,
      this.newPassword.nativeElement.value)
    .subscribe((response : HttpResponse<any>)=>{
      if(response.status == 200||
        response.status == 201||
        response.status == 202||
        response.status == 204) {
        this.toastr.success(response.body.response_message);
      } else {
        this.toastr.error(response.body.response_message);
      }
    }, error => {
      this.toastr.error(error.error.message);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}