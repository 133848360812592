<div >
  <div class="text-right  mb-2">

    <img
    
    style="height: 20px;width: 20px;object-fit: cover;"
    
    src="./assets/img/close.jpg"
    alt=""
    (click)="close()"
    />
  </div>
  <h2 style="font-weight: 600;">Edit Group Details</h2>



<div class="cart-item" *ngFor="let item of items">
  <span> </span>
  <span></span>
</div>

<div  >

  <div class="form-group">
    <label for="name">
    Group  Name
    </label>
    <input id="name" type="text" value="{{groupname}}"  class="form-control h-auto">
  </div>

  <div class="form-group">
    

    <label for="address">
      Change Image
    </label>
  
    <input type="file" id="file" (change)="handleFileInput($event)" class="form-control h-auto"
    accept="image/png, image/gif, image/jpeg" />
  </div>
<div class="pt-3 text-right">
  <button class="button" (click)="onSubmit()">Update</button>
</div>


</div>
     
   </div>
