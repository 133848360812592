
<!-- <div id="loading" class="green-bg">
    <div id="loading-center">
    <div class="boxLoading"></div>
    </div>
    </div> -->
    
    
    <header id="header-wrap" data-spy="affix" data-offset-top="55">
    <div class="container">
    <div class="row">
    <div class="col-sm-12">
    <nav class="navbar navbar-default d-block">
    
    <div class="navbar-header">
    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
    <span class="sr-only">Toggle navigation</span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
    </button>
    <a class="navbar-brand" href="#">
    <img src="./assets/landing/images/logo.png" alt="logo">
    </a>
    </div>
    
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
    <ul class="nav navbar-nav navbar-right d-block" id="top-menu">
    <li class="active"><a href="#iq-home">Home</a></li>
    <li><a href="#about-us">About Us</a></li>
    <li><a href="#features">Features</a></li>
    <li><a href="#contact">Contact Us</a></li>
    </ul>
    </div>
    
    </nav>
    </div>
    </div>
    </div>
    </header>
    
    
    <section id="iq-home" class="bannerq-bg iq-bg-fixed iq-box-shadow iq-over-black-90" style=" background: url(./assets/landing/images/banner/bg.jpg);">
    <div class="container">
    <div class="row banner-text">
    <div class="col-md-8 col-lg-8 hidden-xs hidden-sm">
    <h1 class="iq-font-white iq-tw-8" data-animation="animated fadeInLeft"><small class="iq-font-white iq-tw-6"><b class="iq-font-white">&#9679;</b> CBC provide amazing &amp; outstanding feature</small>CBC MOBILE APP</h1>
    <a class="button bt-white iq-mr-15" href="# "> <i class="ion-social-apple"></i> App Store</a>
    <a class="button bt-white" href="# "> <i class="ion-social-android"></i> Play Store</a>
    </div>
    <div class="col-md-4 col-lg-4">
    <form class="banner-form" [formGroup]="loginForm">
    <div class="form-icon">
    <img class="img-responsive center-block" src="./assets/landing/images/logo-white.png" alt="#">
    </div>
    <h3 class="iq-tw-7"><small>Welcome!</small>
    Please Log in</h3>
    <div class="form-group">
    <label for="exampleInputEmail1" class="text-uppercase">User id</label>
    <input type="email" class="form-control" id="exampleInputEmail1"
    formControlName="userName" placeholder="Email Address">
    <i class="ion-ios-person"></i>
    </div>
    <div class="form-group">
    <label for="exampleInputPassword1" class="text-uppercase">Password</label>
    <input type="password" class="form-control" id="exampleInputPassword1"
    formControlName="password" placeholder="Password">
    <i class="ion-ios-unlocked"></i>
    </div>
    <div class="iq-pt-10 lsRememberMe">
    <input type="checkbox" value="lsRememberMe" formControlName="rememberMe"> <label for="rememberMe">Remember me</label></div>
    <div class="iq-pt-10">
        <div>
    <a><label style="text-align: center"
        style="cursor: pointer;"
        (click)="forgotPassword()">Forgot Password?</label> </a> </div> 
    </div>
    
    <div class="form-group">
        <ngx-recaptcha2
        #captchaElem
        siteKey="{{siteKey}}"
        (success)="handleSuccess($event)"
        [size]="size"
        [hl]="lang"
        [theme]="theme"
        [type]="type"
        formControlName="recaptcha"
      >
      </ngx-recaptcha2>
    </div>
   
    <a class="button btn-block text-center iq-mt-30"
    (click)="userLogin()"
    style="cursor: pointer;">Login</a>
   
    </form>
    </div>
    </div>
    </div>
    </section>
    
    <div class="main-content">
    
    <section class="overview-block-ptb iq-how-it-work">
    <div class="container">
    <div class="row">
    <div class="col-sm-12">
    <div class="heading-title">
    <h2 class="title iq-tw-6">How it Works</h2>
    <div class="divider"></div>
    <h3 class="iq-tw-3 iq-font-light-blue iq-mt-40 iq-plr-90">CBC backups your contacts and can be restored anytime, on any device as required. Make calls to your offline friend using ‘application to mobile’ call feature. Chat with your contacts as needed by sharing your files at a flick of a switch.</h3>
    </div>
    </div>
    </div>
    <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-4">
    <div class="iq-Work-box text-center">
    <div class="Work-icon">
    <i aria-hidden="true" class="ion-ios-pie-outline"></i>
    <div class="line"></div>
    </div>
    <h4 class="iq-tw-6 iq-mt-20 iq-mb-15">Contact Backup</h4>
    <p>We are all aware of how baffling it can be to lose your contacts. Some of the contacts may be really important for personal and/or professional needs.</p>
    </div>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 re-mt-30">
    <div class="iq-Work-box text-center">
    <div class="Work-icon">
    <i aria-hidden="true" class="ion-ios-photos-outline"></i>
    <div class="line"></div>
    </div>
    <h4 class="iq-tw-6 iq-mt-20 iq-mb-15">Contact Restore</h4>
    <p>Backed up contacts can be restored at any time required. Even if the contacts are deleted it can restored using CBC’s restore option.</p>
    </div>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 re-mt-30">
    <div class="iq-Work-box text-center">
    <div class="Work-icon">
    <i aria-hidden="true" class="ion-ios-heart-outline"></i>
    </div>
    <h4 class="iq-tw-6 iq-mt-20 iq-mb-15">Call &amp; Chat</h4>
    <p>Enjoy free voice calls on CBC for both app to app and app to mobile calls and discover many features to help you stay connected with the people in your contact list.</p>
    </div>
    </div>
    </div>
    </div>
    </section>
    
    
    <section id="about-us" class="overview-block-ptb iq-about-1 grey-bg">
    <div class="container">
    <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-6">
    <img class="img-responsive center-block wow fadeInLeft" data-wow-duration="1s" src="./assets/landing/images/01.png" alt="#">
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6">
    <h2 class="heading-left iq-tw-6 iq-mt-40">About Our App</h2>
    <div class="lead iq-tw-6 iq-mb-20">CBC (Cellular Backup Contact) is a mobile application that automatically backs up all iPhone / Android mobile contacts and constantly updates the initial contact changes</div>
    <p>The mobile application allows the users to send and receive SMS and call from the contact list by using either application to application, or application to mobile call. CBC App offers its users easy access anywhere in the world to their contacts if you have access to the internet and a telephone system. CBC application has a “Restore Access” to restore the contacts, a “Backup Access” to initialize the automatic backup after upgrading to a new version hardware/New phone, and a search access to search by names or by area code.</p>
    <a class="button iq-mt-25" href="# ">Download</a>
    </div>
    </div>
    </div>
    </section>
    
    
    <!--<section id="features" class="overview-block-ptb iq-amazing-tab-one white-bg">
    <div class="container">
    <div class="row">
    <div class="col-sm-12">
    <div class="heading-title">
    <h2 class="title iq-tw-6">Special Features</h2>
    <div class="divider"></div>
    <p>Appino is launch with everything you need. We've got a lot of wowful and cool features. so here we go, with unlimited features. go and check out!</p>
    </div>
    </div>
    </div>
    <div class="row">
    <div class="col-sm-12">
    
    <ul class="nav nav-tabs" role="tablist">
    <li role="presentation" class="active"><a href="#home" aria-controls="home" role="tab" data-toggle="tab"><i aria-hidden="true" class="ion-ios-monitor-outline"></i><span>High Resolution</span></a></li>
    <li role="presentation"><a href="#profile" aria-controls="profile" role="tab" data-toggle="tab"><i aria-hidden="true" class="ion-ios-settings"></i><span>Color Scheme</span></a></li>
    <li role="presentation"><a href="#messages" aria-controls="messages" role="tab" data-toggle="tab"><i aria-hidden="true" class="ion-social-googleplus-outline"></i><span>Google Fonts</span></a></li>
    <li role="presentation"><a href="#settings" aria-controls="settings" role="tab" data-toggle="tab"><i aria-hidden="true" class="ion-ios-heart-outline"></i><span>Clean Codes</span></a></li>
    </ul>
    
     <div class="tab-content iq-mt-50">
    <div role="tabpanel" class="tab-pane fade in active active" id="home">
    <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-6">
    <img class="img-responsive center-block" src="./assets/landing/images/03.png" alt="#">
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6">
    <h4 class="heading-left iq-tw-6 iq-mt-30">High Resolution</h4>
    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into</p>
    <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
    <a class="button iq-mt-15" href="# ">Download</a>
    </div>
    </div>
    </div>
    <div role="tabpanel" class="tab-pane fade" id="profile">
    <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-6">
    <h4 class="heading-left iq-tw-6 iq-mt-30">Color Schemes</h4>
    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into</p>
    <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
    <a class="button iq-mt-15" href="# ">Download</a>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6">
    <img class="img-responsive center-block" src="./assets/landing/images/03.png" alt="#">
    </div>
    </div>
    </div>
    <div role="tabpanel" class="tab-pane fade" id="messages">
    <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-6">
    <img class="img-responsive center-block" src="./assets/landing/images/03.png" alt="#">
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6">
    <h4 class="heading-left iq-tw-6 iq-mt-30">Google Fonts</h4>
    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into</p>
    <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
    <a class="button iq-mt-15" href="# ">Download</a>
    </div>
    </div>
    </div>
    <div role="tabpanel" class="tab-pane fade" id="settings">
    <div class="row">
     <div class="col-sm-12 col-md-6 col-lg-6">
    <h4 class="heading-left iq-tw-6 iq-mt-30">Clean Codes</h4>
    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into</p>
    <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
    <a class="button iq-mt-15" href="# ">Download</a>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6">
    <img class="img-responsive center-block" src="./assets/landing/images/03.png" alt="#">
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </section> -->
    
    
    <!--<section id="screenshots" class="overview-block-ptb iq-bg iq-bg-fixed iq-over-black-80 iq-screenshots" style=" background: url(./assets/landing/images/banner/bg.jpg);">
    <div class="container">
    <div class="row">
    <div class="col-sm-12">
    <div class="heading-title">
    <h2 class="title iq-tw-6 iq-font-white">App Screenshots</h2>
    <div class="divider white"></div>
    <p class="iq-font-white">Appino is here with its best screenshot, from this photo gallery you can get idea about this application.</p>
    </div>
    </div>
    </div>
    <div class="row">
    <div class="col-sm-12">
    <div class="owl-carousel popup-gallery" data-nav-dots="true" data-nav-arrow="false" data-items="4" data-sm-items="2" data-lg-items="3" data-md-items="3" data-autoplay="true">
    <div class="item"><a href="./assets/landing/images/screenshots/02.jpg" class="popup-img"><img class="img-responsive" src="./assets/landing/images/screenshots/02.jpg" alt="#"></a></div>
    <div class="item"><a href="./assets/landing/images/screenshots/03.jpg" class="popup-img"><img class="img-responsive" src="./assets/landing/images/screenshots/03.jpg" alt="#"></a></div>
    <div class="item"><a href="./assets/landing/images/screenshots/01.jpg" class="popup-img"><img class="img-responsive" src="./assets/landing/images/screenshots/01.jpg" alt="#"></a></div>
    <div class="item"><a href="./assets/landing/images/screenshots/02.jpg" class="popup-img"><img class="img-responsive" src="./assets/landing/images/screenshots/02.jpg" alt="#"></a></div>
    <div class="item"><a href="./assets/landing/images/screenshots/03.jpg" class="popup-img"><img class="img-responsive" src="./assets/landing/images/screenshots/03.jpg" alt="#"></a></div>
    <div class="item"><a href="./assets/landing/images/screenshots/01.jpg" class="popup-img"><img class="img-responsive" src="./assets/landing/images/screenshots/01.jpg" alt="#"></a></div>
    <div class="item"><a href="./assets/landing/images/screenshots/03.jpg" class="popup-img"><img class="img-responsive" src="./assets/landing/images/screenshots/03.jpg" alt="#"></a></div>
    <div class="item"><a href="./assets/landing/images/screenshots/01.jpg" class="popup-img"><img class="img-responsive" src="./assets/landing/images/screenshots/01.jpg" alt="#"></a></div>
    <div class="item"><a href="./assets/landing/images/screenshots/03.jpg" class="popup-img"><img class="img-responsive" src="./assets/landing/images/screenshots/03.jpg" alt="#"></a></div>
    <div class="item"><a href="./assets/landing/images/screenshots/01.jpg" class="popup-img"><img class="img-responsive" src="./assets/landing/images/screenshots/01.jpg" alt="#"></a></div>
    </div>
    </div>
    </div>
    </div>
    </section> -->
    
    
    <section id="features" class="overview-block-ptb iq-great-feature white-bg">
    <div class="container">
    <div class="row">
    <div class="col-sm-12">
    <div class="heading-title">
    <h2 class="title iq-tw-6">CBC Great Feature</h2>
    <div class="divider"></div>
    <p>We've been working really hard to improve the appino with this amazing new features that you aked for! Check out the appinoâ€™s new amazing features.</p>
    </div>
    </div>
    </div>
    <div class="row">
    <div class="col-md-4">
    <div class="iq-fancy-box-02 iq-mt-100 wow fadeInLeft" data-wow-duration="1s">
    <div class="iq-icon green-bg">
    <i aria-hidden="true" class="ion-ios-checkmark-outline"></i>
    </div>
    <div class="fancy-content">
    <h5 class="iq-tw-6" style="line-height: 4em;">Backup Contacts</h5>
    
    </div>
    </div>
    <div class="iq-fancy-box-02 iq-mt-60 wow fadeInLeft" data-wow-duration="1s">
    <div class="iq-icon green-bg">
    <i aria-hidden="true" class="ion-ios-color-wand-outline"></i>
    </div>
    <div class="fancy-content">
    <h5 class="iq-tw-6" style="line-height: 4em;">Restore contacts</h5>
    
    </div>
    </div>
    <div class="iq-fancy-box-02 iq-mt-60 wow fadeInLeft" data-wow-duration="1s">
    <div class="iq-icon green-bg">
    <i aria-hidden="true" class="ion-ios-copy-outline"></i>
    </div>
    <div class="fancy-content">
    <h5 class="iq-tw-6" style="line-height: 4em;">App to App Call</h5>
    
    </div>
    </div>
    </div>
    <div class="col-md-4 text-center hidden-sm hidden-xs">
    <img src="./assets/landing/images/banner/01.png" class="img-responsive center-block wow fadeInUp" data-wow-duration="1s" alt="#">
    </div>
    <div class="col-md-4">
    <div class="iq-fancy-box-02 iq-mt-100 wow fadeInRight" data-wow-duration="1s">
    <div class="iq-icon green-bg">
    <i aria-hidden="true" class="ion-ios-heart-outline"></i>
    </div>
    <div class="fancy-content">
    <h5 class="iq-tw-6" style="line-height: 4em;">App to Mobile Call</h5>
    
    </div>
    </div>
    <div class="iq-fancy-box-02 iq-mt-60 wow fadeInRight" data-wow-duration="1s">
    <div class="iq-icon green-bg">
    <i aria-hidden="true" class="ion-ios-monitor-outline"></i>
    </div>
    <div class="fancy-content">
    <h5 class="iq-tw-6" style="line-height: 4em;">Chat as you go</h5>
    
     </div>
    </div>
    <div class="iq-fancy-box-02 iq-mt-60 wow fadeInRight" data-wow-duration="1s">
    <div class="iq-icon green-bg">
    <i aria-hidden="true" class="ion-ios-plus-outline"></i>
    </div>
    <div class="fancy-content">
    <h5 class="iq-tw-6" style="line-height: 4em;">Ease to use</h5>
    
    </div>
    </div>
    </div>
    </div>
    </div>
    </section>
    
    
    

    
    </div>
    <div class="footer">
    
   
    
    
    <section id="contact" class="iq-our-touch">
        <div class="container">
            <div class="row">
        <app-contactushome></app-contactushome>
        </div>
        </div>
    </section>
    <footer class="iq-footer white-bg text-center">
    <div class="container">
    <div class="row">
    <div class="col-sm-12">
    <div class="footer-copyright iq-ptb-20">Copyright @<span id="copyright"> <script data-cfasync="false" src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script>document.getElementById('copyright').appendChild(document.createTextNode(new Date().getFullYear()))</script></span> <a href="#" class="text-green">CBC</a> All Rights Reserved </div>
    </div>
    </div>
    </div>
    </footer>
    
    </div>
    
    <div id="back-to-top">
    <a class="top" href="#top"> <i class="ion-ios-upload-outline"></i> </a>
    </div>
    
    
    <!--<div class="iq-customizer closed">
    <div class="buy-button"> <a class="opener" href="#"><i class="fa fa-spinner fa-spin"></i></a> </div>
    <div class="clearfix content-chooser">
    <h3 class="iq-font-black">Appino Awesome Color</h3>
    <p>This color combo available inside whole template. You can change on your wish, Even you can create your own with limitless possibilities! </p>
    <ul class="iq-colorChange clearfix">
    <li class="color-1 selected" data-style="color-1"></li>
    <li class="color-2" data-style="color-2"></li>
    <li class="color-3" data-style="color-3"></li>
    <li class="color-4" data-style="color-4"></li>
    <li class="color-5" data-style="color-5"></li>
    <li class="color-6" data-style="color-6"></li>
    <li class="color-7" data-style="color-7"></li>
    <li class="color-8" data-style="color-8"></li>
    </ul>
    <a target="_blank" class="button" href="">purchase now</a>
    </div>
    </div>-->